<template>
    <div class="modal" v-bind:class="{ 'is-active': showModal }">
        <div class="modal-background" @click="close"></div>
        <div class="modal-content">
            <button class="delete" aria-label="close" @click.prevent="close"></button>
            <div class="box" :class="{ centered: centered }">
                <div class="heading centered large blue bordered">{{ title }}</div>
                <div class="slot-content">
                    <!-- <h4 class="centered">{{ title }}</h4> -->
                    <slot>Loading content...</slot>
                </div>
                <div v-if="showButtons" class="buttons" :class="{ centered: centered }">
                    <button class="button" :class="theme" @click="close()">{{ submitText ? submitText : 'Done' }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        theme: String,
        submitText: String,
        showButtons: Boolean,
        centered: Boolean
    },
    mounted() {},
    methods: {
        close() {
            this.showModal = false;
            let result = {
                answer: 'Modal closed',
                modal: false
            };
            this.$emit('closeModal', result);
        }
    },
    data() {
        return {
            showModal: true
        };
    }
};
</script>

<style lang="scss" scoped>
.slot-content {
    margin-bottom: 1rem;
}
// .modal-content, .modal-card {
//     width: 95%;
//     max-width: 1400px;
//     &:not(.fullwidth) {
//         max-width: 640px;
//     }
//     .delete {
//       position: absolute;
//       z-index: 999;
//       background: #ddd;
//       top: 8px;
//       padding: 15px;
//       right: 8px;
//       transition: 0.25s;
//       &:hover {
//         background: red;
//       }
//     }
// }

.heading {
    margin-bottom: 12px !important;
}

@media (min-width: 1024px) {
}
</style>
