<template>
    <!-- <button class="button quick" @click="proceed()" :class="theme">
        <div class="cb-icon">
            <div class="cb-icon-box"><font-awesome-icon :icon="icon" /></div>
        </div>
        <div class="cb-text">
            <div class="cb-title">{{ title }}</div>
            <div class="cb-subtitle">{{ subtitle }}</div>
        </div>
        <div class="cb-pro-icon" v-if="!access"><font-awesome-icon icon="lock" /></div>
    </button> -->

    <div class="solid-button" @click="proceed()" :style="{ backgroundColor: `${buttonColor}` }">
        <div class="round-button-letters">
            <span :style="{ color: `${buttonColor}` }"><font-awesome-icon class="free" :icon="icon" /></span>
        </div>
        <div class="content-box">
            <div class="heading">{{ title }}</div>
            <span>{{ subtitle }}</span>
        </div>
        <div class="clear"></div>
    </div>
</template>

<script>
export default {
    props: {
        path: String,
        theme: String,
        title: String,
        subtitle: String,
        icon: String,
        size: String,
        access: Boolean,
        buttonColor: String
    },
    mounted() {},
    methods: {
        proceed() {
            if (this.access) {
                console.log('Welcome..');
            } else {
                console.log('Back of the line, china!');
            }
        }
    },
    data() {
        return {};
    }
};
</script>

<style lang="scss" scoped>
button.quick {
    display: flex;
    padding: 12px 20px;
    height: auto;
    justify-content: start;
    // min-width: 480px;
    overflow: hidden;
    margin-bottom: 12px;
    transition: 0.2s;
    width: -webkit-fill-available;
    .cb-icon {
        flex: 0 0 65px;
        .cb-icon-box {
            border: 1px solid #ccc;
            border-radius: 50%;
            width: 45px;
            height: 45px;
            align-items: center;
            display: flex;
            justify-content: center;
            color: #999;
        }
    }
    .cb-text {
        align-items: start;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .cb-title {
            font-size: 1.1rem;
            font-family: 'Open Sans', sans-serif;
            text-transform: uppercase;
        }
        .cb-subtitle {
            font-size: 0.9rem;
            color: #888;
        }
    }
    .cb-pro-icon {
        position: absolute;
        top: 5px;
        right: 5px;
        color: #fb6e00;
        background: #fff;
        width: 23px;
        height: 23px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
    &.green {
        // background: #5bd865;
        // color: #fff;
        border-color: #5bd865;
        .cb-icon {
            .cb-icon-box {
                color: #5bd865;
                border-color: #5bd865;
            }
        }
        .cb-text {
            .cb-title {
                color: #5bd865;
            }
            .cb-subtitle {
                color: #5bd865;
            }
        }
    }
    &:focus {
        box-shadow: none;
    }
    &:hover {
        opacity: 0.8;
    }
}

@media (max-width: 1024px) {
    button.button {
        max-width: 480px;
        min-width: 0;
    }
}
</style>
