<template>
    <div class="mobile-nav">
        <div class="menu-logo">
            <RouterLink to="/"><img class="logo" src="@/assets/img/offshoresms-new-logo.png" alt="Offshore SMS" /></RouterLink>
        </div>

        <!-- <div class="field">
          <input id="switchExample" type="checkbox" name="switchExample" class="switch is-rounded" :checked="colorOn" @change="toggleColor">
          <label for="switchExample">Simple Colours</label>
        </div> -->

        <ul class="top-nav-menu">
            <!-- <li v-if="currentVesselBasicData && currentVesselBasicData.vesselID" class="menu-icon clickable" @click="toVesselPage()">
                <div class="round-button-letters vessel pad">
                    <span>{{ makeLetters(currentVesselBasicData.vesselName) }}</span>
                </div>
            </li>
            <li v-if="currentFleet.id" class="menu-icon clickable" @click="toFleetDashboard()">
                <div class="round-button-letters pad">
                    <span>{{ makeLetters(currentFleet.companyName) }}</span>
                </div>
            </li> -->
            <li><div class="version">v1.3.11</div></li>
            <li v-if="!isFreeStanding || userIsLoggedIn">
                <RouterLink to="/my-profile"
                    ><span class="menu-icon profile" :class="{ current: currentRoute == 'MyProfile' }"><font-awesome-icon icon="user-cog" /></span
                ></RouterLink>
            </li>
            <li v-if="!isFreeStanding || userIsLoggedIn">
                <RouterLink to="/"
                    ><span class="menu-icon dashboard" :class="{ current: currentRoute == 'MyHomePage' }"
                        ><font-awesome-icon class="free" icon="home" /></span
                ></RouterLink>
            </li>
        </ul>
    </div>
</template>

<script>
import { reactive } from 'vue';
import { loggedInUserStore } from '@/stores/userdata';
import { currentDataStore } from '@/stores/currentdata';

export default {
    setup() {
        const user_store = loggedInUserStore();
        const current_data = currentDataStore();
        // Return all consts to access in template
        // return {user: user, anotherVariable: anotherVariable}
        return {
            user_store: user_store,
            current_data: current_data
        };
    },
    computed: {
        userData() {
            return this.user_store.getUserData;
        },
        userIsLoggedIn() {
            return this.user_store.getUserIsLoggedIn;
        },
        colorOn() {
            return this.current_data.getColorOn;
        },
        currentFleetID() {
            return this.current_data.getCurrentFleetID;
        },
        currentFleet() {
            return this.current_data.getCurrentFleet;
        },
        isFreeStanding() {
            return this.currentRoute == 'SmsSnapshot' && !this.userIsLoggedIn;
        },
        currentVesselBasicData() {
            return this.current_data.getCurrentVesselBasicData;
        }
    },
    mounted() {
        return (this.currentRoute = this.$route.name);
    },
    data() {
        return {
            currentRoute: ''
        };
    },

    methods: {
        toggleColor() {
            this.current_data.setColorOn(!this.colorOn);
        },

        makeLetters(displayName) {
            // console.log(this.currentFleet.id);
            let res = displayName.length > 1 ? displayName.split(' ') : displayName;
            if (res.length > 1) {
                return res[0].substring(0, 1) + res[1].substring(0, 1);
            } else {
                return res[0].substring(0, 1);
            }
        },
        toVesselPage() {
            this.current_data.setCurrentVesselTab('1');
            this.current_data.setCurrentVessel(this.currentVesselBasicData.vesselID);
            this.current_data.setCurrentVesselCrews(this.currentVesselBasicData.vesselID);
            this.current_data.setSnapshotSections([]);
            this.$router.push({
                path: '/manage-vessel'
            });
        },

        toFleetDashboard() {
            if (this.isFreeStanding) return;
            // this.current_data.setSnapshotSections([]);
            this.$router.push({
                path: '/fleet-dashboard'
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    place-items: center;
    margin-right: 1rem;
    background: #fff;
    z-index: 3002;
    min-height: 52px;
    padding-left: 95px;
    .menu-logo {
        width: 136px;
    }
    .top-nav-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            display: inline-block;
            a {
                padding: 8px 5px;
                display: block;
            }
            .version {
                color: grey;
            }
        }
    }
    .menu-icon {
        color: #9a9a9a;
        font-size: 22px;
        margin: 7px;
        transition: 0.25s;
        &.profile {
            &.current,
            &:hover {
                color: orange;
            }
        }
        &.dashboard {
            &.current,
            &:hover {
                color: mediumseagreen;
            }
        }
    }
}
input[type='checkbox']:focus {
    outline: none;
}
.switch[type='checkbox']:focus + label::after,
.switch[type='checkbox']:focus + label::before,
.switch[type='checkbox']:focus + label:after,
.switch[type='checkbox']:focus + label:before {
    outline: none !important;
}

.round-button-letters {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    // text-align: center;
    position: relative;
    background: orange;
    display: flex;
    // margin: 10px 10px 25px;
    margin: 0;
    float: left;
    transition: 0.2s;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &.vessel {
        background: steelblue;
    }
    &:hover {
        background: cadetblue;
    }
    &.pad {
        margin: 0 5px;
    }
    span {
        // display: block;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%,-50%);
        // line-height: 1rem;
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        display: block;
        padding-top: 0;
    }
}
</style>
