<template>
    <div class="item">
        <div class="details">
            <slot></slot>

            <div class="company-link" @click="toFleet()">
                <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
                <div class="round-button-letters">
                    <span>{{ makeLetters(sentFleet.companyName) }}</span>
                </div>
                <div class="content-box">
                    <h4>{{ sentFleet.companyName }}</h4>
                    <span>{{ sentFleet.companyState }}</span>
                </div>

                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { currentDataStore } from '@/stores/currentdata';
export default {
    setup() {
        return {
            current_data: currentDataStore()
        };
    },
    props: {
        sentFleet: Object
    },
    mounted() {},
    methods: {
        async toFleet() {
            let self = this;
            this.isLoading = true;
            setTimeout(() => {
                this.current_data.setCurrentFleet(this.sentFleet.companyID);
                this.current_data.setFleetItemTags();
                this.current_data.setFleetTeamTags();
                this.current_data.setFleetAssetTags();
                this.current_data.SETCURRENTASSETGROUPS(this.sentFleet.companyID);
                this.current_data.SETCURRENTADMINPERMISSIONS(this.sentFleet.companyID);
                this.$router.push({
                    name: 'FleetDashboard',
                    params: {
                        loadAssetData: 'yes'
                    }
                });
            }, 500);
        },
        makeLetters(companyName) {
            let res = companyName.split(' ');
            if (res.length > 1) {
                return res[0].substring(0, 1) + res[1].substring(0, 1);
            } else {
                return res[0].substring(0, 1);
            }
        }
    },
    data() {
        return {
            isLoading: false,
            loadAssetData: true
        };
    }
};
</script>

<style lang="scss" scoped>
.item {
    // margin-top: 2rem;
    display: flex;
    text-align: left;
}

.details {
    flex: 1;
}

h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 0.4rem;
    color: var(--color-heading);
}

.company-link {
    margin-bottom: 1rem;
    cursor: pointer;
    background: steelblue;
    border-radius: 4px;
    transition: 0.3s;
    display: flex;
    place-items: center;
    padding: 8px 0;
    // max-width: 400px;
    .round-button-letters {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        position: relative;
        background: #fff;
        display: block;
        // margin: 10px 10px 25px;
        margin-left: 1rem;
        float: left;
        transition: 0.3s;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        span {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 1rem;
            font-size: 15px;
            color: steelblue;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    .content-box {
        text-align: left;
        padding: 10px;
        width: calc(100% - 65px);
        h4 {
            text-align: left;
            color: #fff;
            margin-bottom: 0;
            // text-transform: uppercase;
            font-weight: bold;
            font-size: 1rem;
            line-height: 17px;
            padding-top: 4px;
            word-break: break-all;
            font-family: 'Open Sans', sans-serif;
            letter-spacing: 0.5px;
        }
        span {
            font-size: 0.9rem;
            color: #fff;
            word-break: break-all;
        }
    }
    &:hover {
        background: #5aa6e5;
    }
}

@media (max-width: 1024px) {
    .item {
        margin-top: 0;
        padding: 0;
    }
}
</style>
