<template>
    <div class="tab-wrapper">
        <!-- <div :class="{ selected: tab == '1' }" @click="tab = '1'" class="round-tab">
            <span class="icon"><font-awesome-icon icon="tools" /></span>
            <span class="text">My Tools</span>
        </div>

        <div :class="{ selected: tab == '2' }" @click="tab = '2'" class="round-tab">
            <span class="icon"><font-awesome-icon icon="clipboard" /></span>
            <span class="text">Marine Assets</span>
        </div> -->

        <div :class="{ selected: tab == '3' }" @click="tab = '3'" class="round-tab">
            <span class="icon"><font-awesome-icon icon="ship" /></span>
            <span class="text">Marine Fleets</span>
        </div>
    </div>

    <div class="tab-content-outer">
        <HomePageTab v-if="tab == '1'" isFirst tabClass="three">
            <template #heading>
                <h4 class="centered">Profile & Tools</h4>
                <div class="spacer s20"></div>
            </template>
            <template #tab>
                <div class="tab-grid">
                    <div>
                        <RoundIconButton
                            icon="circle-user"
                            title="Mariner Profile"
                            :inverted="true"
                            path="/mariner-profile"
                            color="hotpink"
                            fontSize="70px" />
                    </div>
                    <div>
                        <RoundIconButton icon="address-card" title="Qualifications" color="#b073d4" />
                    </div>
                    <div>
                        <RoundIconButton icon="globe" title="Induction History" color="#4da064" />
                    </div>
                    <div>
                        <RoundIconButton icon="clipboard-list" title="Notes & ToDo List" color="orange" />
                    </div>
                    <div>
                        <RoundIconButton icon="stopwatch" title="All Timers" color="#347dc5" />
                    </div>
                    <div>
                        <RoundIconButton icon="project-diagram" title="Workflows" />
                    </div>
                </div>
                <!-- <div class="tab-content">
                    <div class="tc-left">
                        <div class="home-steps-list">
                            <div class="step-item">
                                <div class="counter">
                                    <span>1</span>
                                </div>
                                <div class="details">Contacts & Emergency Info.</div>
                            </div>
                            <div class="step-item">
                                <div class="counter">
                                    <span>2</span>
                                </div>
                                <div class="details">My Qualifications.</div>
                            </div>
                            <div class="step-item">
                                <div class="counter">
                                    <span>3</span>
                                </div>
                                <div class="details">Inductions.</div>
                            </div>
                            <div class="step-item">
                                <div class="counter">
                                    <span>4</span>
                                </div>
                                <div class="details">Personal Timers.</div>
                            </div>
                            <div class="step-item">
                                <div class="counter">
                                    <span>5</span>
                                </div>
                                <div class="details">Workflows.</div>
                            </div>
                        </div>
                    </div>
                    <div class="tc-right"></div>
                </div> -->
            </template>
        </HomePageTab>

        <HomePageTab v-if="tab == '2'" isFirst tabClass="two">
            <template #heading>
                <!-- <h4 class="centered">Complete some of the main asset logs on the web!</h4> -->
                <h4 class="centered">A digital logs portal for the Marine Assets you work on</h4>
                <div class="spacer s20"></div>
            </template>
            <template #tab>
                <div class="tab-content">
                    <div class="tc-left">
                        <!-- <div class="home-steps-list">
                            <div class="step-item">
                                <div class="counter">
                                    <span><font-awesome-icon icon="pen" /></span>
                                </div>
                                <div class="details">
                                    Now you can complete some of the most important asset logs on the OffshoreSMS website as well as the mobile app.
                                </div>
                            </div>
                            <div class="step-item">
                                <div class="counter">
                                    <span><font-awesome-icon icon="info-circle" /></span>
                                </div>
                                <div class="details">
                                    Remember that the OffshoreSMS app is still the best way to log anything and everything to do with the marine
                                    assets that you own or skipper.
                                </div>
                            </div>
                            <div class="step-item">
                                <div class="counter">
                                    <span><font-awesome-icon icon="globe" /></span>
                                </div>
                                <div class="details">
                                    Click the Apple App Store or Google Play Store links below to download the OffshoreSMS app onto your smart phone.
                                </div>
                            </div>
                        </div> -->
                        <RoundIconButton
                            icon="users"
                            iconSize="90px"
                            fontSize="30px"
                            title="Are you a crew or skipper?"
                            subtitle="Click here to see what tools are available"
                            :isHorizontal="true"
                            color="#de4ab3"
                            borderSize="7px"
                            :preventClick="true"
                            v-on:action="setInfoData('', 'Are you a crew or skipper?')" />
                        <RoundIconButton
                            icon="ship"
                            iconSize="90px"
                            fontSize="30px"
                            title="Can't find your vessel?"
                            subtitle="Click here to get access to the right vessel or other marine asset"
                            :isHorizontal="true"
                            color="#17c5d3"
                            borderSize="7px"
                            :preventClick="true"
                            v-on:action="setInfoData('', 'Can\'t find your vessel?')" />
                        <RoundIconButton
                            icon="ship"
                            iconSize="90px"
                            fontSize="30px"
                            title="Want to know how logs work?"
                            subtitle="Learn how this logs portal compares with the OffshoreSMS mobile app"
                            :isHorizontal="true"
                            color="cadetblue"
                            borderSize="7px"
                            :preventClick="true"
                            v-on:action="setInfoData('', 'Want to know how logs work?')" />
                        <div class="spacer s20"></div>
                        <div class="download mini">
                            <a href="https://apps.apple.com/us/app/offshoresms/id1526656158" target="_blank" rel="noreferrer">
                                <img src="@/assets/img/app-store.png" alt="app store" />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=offshoresms.appdev.nybble.com.smsapp"
                                target="_blank"
                                rel="noreferrer">
                                <img src="@/assets/img/play-store.png" alt="play store" />
                            </a>
                        </div>
                    </div>
                    <div class="tc-right pad-left">
                        <div class="">
                            <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
                            <DashboardCrewedVessels v-on:clicked="showAssetModal" />
                            <div class="spacer s30"></div>
                            <div class="buttons centered">
                                <button class="button is-primary is-medium" @click="showSearchAssetModal = true">
                                    <font-awesome-icon icon="search" /> &nbsp;Search for a Marine Asset
                                </button>
                                <span class="button is-medium is-dark" @click="reloadCrewedVessels()"><font-awesome-icon icon="refresh" /></span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </HomePageTab>
        <HomePageTab v-if="tab == '3'" isFirst tabClass="one">
            <template #heading>
                <!-- <h4 class="centered">Manage your Marine Assets</h4> -->
                <h4 class="centered">All of your Marine Assets are grouped into Fleets</h4>
                <div class="spacer s20"></div>
            </template>
            <template #tab>
                <div class="tab-content">
                    <div class="tc-left">
                        <!-- <div class="home-steps-list">
                            <div class="step-item">
                                <div class="counter">
                                    <span><font-awesome-icon icon="pen" /></span>
                                </div>
                                <div class="details">
                                    Now you can complete some of the most important asset logs on the OffshoreSMS website as well as the mobile app.
                                </div>
                            </div>
                            <div class="step-item">
                                <div class="counter">
                                    <span><font-awesome-icon icon="info-circle" /></span>
                                </div>
                                <div class="details">
                                    Remember that the OffshoreSMS app is still the best way to log anything and everything to do with the marine
                                    assets that you own or skipper.
                                </div>
                            </div>
                            <div class="step-item">
                                <div class="counter">
                                    <span><font-awesome-icon icon="globe" /></span>
                                </div>
                                <div class="details">
                                    Click the Apple App Store or Google Play Store links below to download the OffshoreSMS app onto your smart phone.
                                </div>
                            </div>
                        </div> -->
                        <div class="info-boxes">
                            <RoundIconButton
                                icon="users"
                                iconSize="90px"
                                fontSize="30px"
                                title="Welcome to the new dashboard"
                                subtitle="Need any help getting to know your way around?"
                                :isHorizontal="true"
                                color="#17c5d3"
                                borderSize="7px"
                                :preventClick="true"
                                v-on:action="setInfoData('', 'Are you a crew or skipper?')" />
                            <!-- <RoundIconButton
                                icon="clipboard-list"
                                iconSize="90px"
                                fontSize="30px"
                                title="Need help with an SMS?"
                                subtitle="Build a new safety management system for any vessel or marine asset"
                                :isHorizontal="true"
                                color="#17c5d3"
                                borderSize="7px"
                                :preventClick="true"
                                v-on:action="setInfoData('sms', 'Need help with an SMS?')" />
                            <RoundIconButton
                                icon="ship"
                                iconSize="90px"
                                fontSize="30px"
                                title="Add your vessel to OffshoreSMS"
                                subtitle="This could be a new vessel or one you own and operate already"
                                :isHorizontal="true"
                                color="cadetblue"
                                borderSize="7px"
                                :preventClick="true"
                                v-on:action="setInfoData('addVessel', 'Add your vessel to OffshoreSMS')" /> -->
                            <RoundIconButton
                                icon="info-circle"
                                iconSize="90px"
                                fontSize="30px"
                                title="Do you manage a marine fleet?"
                                subtitle="Find out whether you need to join an existing fleet or add your own"
                                :isHorizontal="true"
                                color="orange"
                                borderSize="7px"
                                :preventClick="true"
                                v-on:action="setInfoData('', 'Do you manage a marine fleet?')" />
                            <RoundIconButton
                                icon="users"
                                iconSize="90px"
                                fontSize="30px"
                                title="Are you a crew or skipper?"
                                subtitle="Click here to see what tools are available"
                                :isHorizontal="true"
                                color="#de4ab3"
                                borderSize="7px"
                                :preventClick="true"
                                v-on:action="setInfoData('', 'Are you a crew or skipper?')" />
                        </div>
                        <!-- <hr /> -->
                        <!-- <div class="buttons centered">
                            <button
                                :disabled="isLoading"
                                class="button is-warning is-medium"
                                @click="hasFleets ? showAddAsset() : (showFleetPrompt = true)">
                                <font-awesome-icon icon="plus" /> &nbsp;Add a Marine Asset
                            </button>
                        </div> -->
                    </div>
                    <div class="tc-right">
                        <div class=""><DashboardAllFleets v-on:action="showAddFleet" /></div>
                        <hr />
                        <div class="buttons centered">
                            <button class="button is-primary is-medium" @click="showAddFleetModal = true">
                                <font-awesome-icon icon="plus" /> &nbsp; Set up a New Fleet
                            </button>
                        </div>
                        <div class="spacer s30"></div>
                    </div>
                </div>
            </template>
        </HomePageTab>

        <ReturnFormValuesModal
            v-if="showAddVessel"
            theme="is-primary"
            title="Create a New Asset"
            submitText="Save New Asset"
            :sentForm="formNewVessel"
            @sendResult="getVesselResult($event)">
        </ReturnFormValuesModal>

        <InfoModal v-if="showFleetPrompt" theme="is-primary" title="Add a New Fleet" submitText="Done" @closeModal="close()">
            <div class="">
                <div class="spacer s10"></div>
                <p>
                    <font-awesome-icon icon="exclamation-triangle" /> &nbsp;It looks like you haven't set up or been added to a fleet yet, so please
                    read on.
                </p>
                <div class="spacer s10"></div>
                <p>
                    <font-awesome-icon icon="info-circle" /> &nbsp;A fleet is simply a way to keep all of your assets, crews and activity under one
                    roof, and can be the name of your business, department or region.
                </p>
                <div class="spacer s10"></div>
                <p>
                    <font-awesome-icon icon="list" /> &nbsp;Before you can add a new marine asset, you will need to add your own fleet, or be added to
                    another fleet.
                </p>
                <!-- <div class="spacer s10"></div> -->
                <!-- <p>
                    If you are working for someone else's business, please request to be added as an administrator of their fleet instead of adding
                    another one with the same name.
                </p> -->
                <div class="spacer s10"></div>

                <div class="spacer s30"></div>
                <div class="buttons centered">
                    <button class="button is-primary" @click="close()">Got it</button>
                    <!-- <button class="button is-info" @click="addFleetVessel()">Take me to Fleets</button> -->
                </div>
                <!-- <div class="spacer s10"></div> -->
            </div>
        </InfoModal>

        <!-- <SimpleModal
            v-if="showLogsModal"
            theme="is-primary"
            :fullHeight="true"
            :fullWidth="false"
            :title="selectedAsset.vesselName"
            submitText="Done"
            @closed="close()">
            <div class="spacer s20"></div>
            <div class="logs-grid">
                <SolidButton buttonColor="cadetblue" icon="life-ring" title="View the SMS" subtitle="The safety management system" />
                <SolidButton buttonColor="#333" icon="info-circle" title="Asset Information" subtitle="All vessel information" />
            </div>
            <hr />
            <div class="logs-grid">
                <SolidButton buttonColor="#63cffc" icon="ship" title="Ship's Log" subtitle="Log anything and everything" />
                <SolidButton buttonColor="#f6ba53" icon="tools" title="Hazards & Defects" subtitle="All items needing attention" />
                <SolidButton buttonColor="#6aca7d" icon="play-circle" title="New Startup Log" subtitle="Start a new Trip" />
                <SolidButton buttonColor="#e97878" icon="exclamation-triangle" title="Incident Report" subtitle="Log an incident or near miss" />
                <SolidButton buttonColor="#b87070" icon="circle-stop" title="Shutdown Log" subtitle="Finish the current trip" />
            </div>
            <div class="spacer s10"></div>
        </SimpleModal> -->

        <InfoModal v-if="showLogsModal" theme="is-primary" :fullHeight="true" :fullWidth="false" submitText="Done" @closeModal="close()">
            <div class="heading centered large blue bordered">{{ selectedAsset.vesselName }}</div>
            <div class="logs-grid">
                <SolidButton buttonColor="cadetblue" icon="life-ring" title="View the SMS" subtitle="The safety management system" />
                <SolidButton buttonColor="#333" icon="info-circle" title="Asset Information" subtitle="All vessel information" />
            </div>
            <hr />
            <div class="logs-grid">
                <SolidButton buttonColor="#63cffc" icon="ship" title="Ship's Log" subtitle="Log anything and everything" />
                <SolidButton buttonColor="#f6ba53" icon="tools" title="Hazards & Defects" subtitle="All items needing attention" />
                <SolidButton buttonColor="#6aca7d" icon="play-circle" title="New Startup Log" subtitle="Start a new Trip" />
                <SolidButton buttonColor="#e97878" icon="exclamation-triangle" title="Incident Report" subtitle="Log an incident or near miss" />
                <SolidButton buttonColor="#b87070" icon="circle-stop" title="Shutdown Log" subtitle="Finish the current trip" />
            </div>
        </InfoModal>

        <!-- <SimpleModal
            v-if="showGeneralModal"
            theme="is-primary"
            :fullHeight="true"
            :fullWidth="false"
            :title="selectedAsset.vesselName"
            @closed="close()">
            <div class="spacer s20"></div>
            <div class="home-steps-list">
                <div class="step-item" v-for="(item, idx) in selectedInfoItems" :key="`item-2-${idx}`">
                    <div class="counter">
                        <span><font-awesome-icon :icon="item.icon" /></span>
                    </div>
                    <div class="details">
                        {{ item.details }}
                    </div>
                </div>
            </div>
            <div class="spacer s20"></div>
        </SimpleModal> -->

        <InfoModal
            v-if="showGeneralModal"
            theme="is-primary"
            :fullHeight="true"
            :fullWidth="false"
            :title="selectedAsset.vesselName"
            @closeModal="close()">
            <div class="heading centered large blue bordered">{{ selectedInfoTitle }}</div>
            <div class="home-steps-list">
                <div class="step-item" v-for="(item, idx) in selectedInfoItems" :key="`item-2-${idx}`">
                    <div class="counter">
                        <span><font-awesome-icon :icon="item.icon" /></span>
                    </div>
                    <div class="details">
                        {{ item.details }}
                    </div>
                </div>
            </div>
        </InfoModal>

        <SimpleModal
            v-if="showAddFleetModal"
            :disabled="showAddCompanyButton"
            showComplete
            completeText="Save New Fleet"
            theme="is-primary"
            title="Add a New Fleet"
            :showLoading="addingFleet"
            @submit="saveNewFleet()"
            @closed="close()">
            <div class="spacer s20"></div>
            <h5 class="centered bigger red">
                If this fleet may be set up on OffshoreSMS already, please check with the fleet manager or owner before adding a duplicate fleet.
            </h5>
            <h5 class="centered bigger blue">If you are the owner or manager of this fleet, please add the new fleet details below.</h5>
            <div class="spacer s20"></div>
            <div>
                <div class="form-group-reg">
                    <label class="label">Fleet Name:</label>
                    <input class="input" type="text" placeholder="Type the Fleet name..." v-model="newcompany.companyName" required />
                </div>
            </div>
            <div class="spacer s20"></div>
            <div>
                <div class="form-group-reg">
                    <label class="label">Where is this fleet based?</label>
                    <input class="input" type="text" placeholder="Name of town or port..." v-model="newcompany.fleetLocation" required />
                </div>
            </div>
            <div class="spacer s40"></div>
        </SimpleModal>
    </div>
</template>

<script>
import HomePageTab from './HomePageTab.vue';
import SolidButton from '@/components/buttons/SolidButton.vue';
import DashboardAllFleets from '@/components/dashboard/DashboardAllFleets.vue';
import DashboardCrewedVessels from '@/components/dashboard/DashboardCrewedVessels.vue';
// import { currentDataStore } from '@/stores/currentdata';
import { loggedInUserStore } from '@/stores/userdata';
import ReturnFormValuesModal from '@/components/modals/ReturnFormValuesModal.vue';
import InfoModal from '@/components/modals/InfoModal.vue';
import SimpleModal from '@/components/modals/SimpleModal.vue';
import RoundIconButton from '@/components/buttons/RoundIconButton.vue';
// import { faRubleSign } from '@fortawesome/free-solid-svg-icons';

import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { v4 as uuidv4 } from 'uuid';
// const functions = getFunctions();
// const addRevision = httpsCallable(functions, 'addRevision');
// const adminCreateCompany = httpsCallable(functions, 'adminCreateCompany');

export default {
    setup() {
        // const current_data = currentDataStore();
        const user_store = loggedInUserStore();
        const addRevision = httpsCallable(getFunctions(), 'addRevision');
        const adminCreateCompany = httpsCallable(getFunctions(), 'adminCreateCompany');
        const createNewFleet = httpsCallable(getFunctions(), 'createNewFleet');
        return {
            user_store: user_store,
            db: getFirestore(),
            functions: getFunctions(),
            addRevision: addRevision,
            adminCreateCompany: adminCreateCompany,
            createNewFleet: createNewFleet
            // current_data: current_data
        };
    },
    components: {
        HomePageTab,
        SolidButton,
        DashboardAllFleets,
        DashboardCrewedVessels,
        ReturnFormValuesModal,
        InfoModal,
        SimpleModal,
        RoundIconButton
    },
    computed: {
        stateMyFleets() {
            return this.user_store.getMyFleets;
        },
        formFleetList() {
            return this.stateMyFleets.map((n) => {
                return {
                    label: n.companyName,
                    val: n.companyID
                };
            });
        },
        userData() {
            return this.user_store.getUserData;
        },
        hasFleets() {
            return this.stateMyFleets.length > 0;
        },
        showAddCompanyButton() {
            return this.newcompany.companyName == '' || this.newcompany.fleetLocation == '';
        }
    },
    mounted() {},
    data() {
        return {
            tab: '3',
            isLoading: false,
            showAddVessel: false,
            formNewVessel: [
                {
                    label: 'Type of Asset:',
                    type: 'dropdown',
                    options: [
                        { label: 'Vessel', val: 'vessel' },
                        { label: 'Vehicle', val: 'vehicle' },
                        { label: 'Premises', val: 'premises' },
                        { label: 'Other', val: 'other' }
                    ],
                    placeholder: 'Select one..',
                    returnType: 'text',
                    answer: '',
                    fieldName: 'assetType',
                    required: true
                },
                {
                    label: 'Name of Asset:',
                    type: 'text',
                    options: [],
                    placeholder: 'Type your asset name..',
                    returnType: 'text',
                    answer: '',
                    fieldName: 'vesselName',
                    required: true
                },
                {
                    label: 'Vessel identifier:',
                    type: 'text',
                    options: [],
                    placeholder: 'Type identification number..',
                    returnType: 'text',
                    answer: '',
                    fieldName: 'vesselUVI',
                    required: true
                },
                {
                    label: "What is this vessel's home port?",
                    type: 'text',
                    options: [],
                    placeholder: 'Type home port..',
                    returnType: 'text',
                    answer: '',
                    fieldName: 'homePort',
                    required: true
                },
                {
                    label: 'Add to a Fleet:',
                    type: 'dropdown',
                    options: [],
                    placeholder: 'Select one..',
                    returnType: 'text',
                    answer: '',
                    fieldName: 'fleetID',
                    required: true
                }
            ],
            showFleetPrompt: false,
            newcompany: {
                companyName: '',
                fleetLocation: ''
            },
            showLogsModal: false,
            selectedAsset: {},
            generalInfoData: {
                sms: [
                    {
                        icon: 'info-circle',
                        details:
                            'All commercial vessels require a safety management system. OffshoreSMS makes it really easy to set up your new vessel or other marine asset, and then buld an SMS for that asset.'
                    },
                    {
                        icon: 'info-circle',
                        details:
                            'You have the option to build your own SMS in your fleet dashboard, or get us to help. We can save you time and money by creating a new SMS based on the information you give us about your vessel, asset or operation.'
                    }
                ],
                addVessel: [
                    {
                        icon: 'info-circle',
                        details:
                            "The first step is to add a new Fleet (if you haven't got one set up already). This could be the name of your business, organisation, region or any other way to identify the management of the vessel."
                    },
                    {
                        icon: 'info-circle',
                        details: 'Then navigate into your fleet and follow the prompts to add a new fleet asset (any vessel or marine asset).'
                    },
                    {
                        icon: 'info-circle',
                        details: "Remember our support is free so don't hesitate to get in touch.'"
                    }
                ],
                fleet: [
                    { icon: 'info-circle', details: 'steve' },
                    { icon: 'info-circle', details: 'the' },
                    { icon: 'info-circle', details: 'boss' }
                ]
            },
            selectedInfoItems: [],
            selectedInfoTitle: '',
            showGeneralModal: false,
            showAddFleetModal: false,
            addingFleet: false
        };
    },
    methods: {
        close() {
            this.showAddVessel = false;
            this.showFleetPrompt = false;
            this.showLogsModal = false;
            this.selectedAsset = {};
            this.showGeneralModal = false;
            this.showAddFleetModal = false;
            this.addingFleet = false;
        },
        reloadCrewedVessels() {
            this.isLoading = true;
            this.user_store.setMyCrewedVesselsLoaded(false);
            this.user_store.setCurrentCrewedVessels();
            setTimeout(() => {
                this.isLoading = false;
            }, 1000);
        },

        showAddFleet(result) {
            // console.log(result);
            this.showAddFleetModal = true;
        },

        showAddAsset() {
            this.formNewVessel[4].options = this.formFleetList;
            this.showAddVessel = true;
        },

        setInfoData(item, title) {
            this.selectedInfoItems = this.generalInfoData[item];
            this.selectedInfoTitle = title;
            this.showGeneralModal = true;
        },

        showAssetModal(result) {
            this.selectedAsset = result.asset;
            this.showLogsModal = true;
        },

        async updateMondayStatus(newCompanyID) {
            let updateRef = doc(this.db, 'companies', newCompanyID);
            await updateDoc(updateRef, {
                mondayStatus: {
                    companyInMonday: true,
                    activityStatus: 'Inactive',
                    billingStatus: 'None',
                    fleetType: 'Unknown',
                    journeyStage: 'In Sales',
                    totalAssets: '',
                    totalStaff: '',
                    fleetGroup: 'topics',
                    industry: 'Unknown'
                }
            });
        },

        addToMonday(fleet, newCompanyID) {
            let self = this;

            let busID = newCompanyID;
            let billingStatus = 'None';
            let activityStatus = 'Inactive';
            let fleetType = 'Unknown';
            let journeyStage = 'In Sales';
            let totalAssets = '';
            let totalStaff = '';
            let fleetGroup = 'topics';
            let industry = 'Unknown';
            let comments = '';

            let d = new Date();
            let d1 = d.getFullYear() + '-' + (d.getMonth() + 1).toString().padStart(2, '0') + '-' + d.getDate().toString().padStart(2, '0');
            console.log('d', d);
            console.log('d1', d1);

            let colValues =
                '{\\"date\\":\\"' +
                d1 +
                '\\",\\"text\\":\\"' +
                busID +
                '\\",\\"status\\":\\"' +
                fleetType +
                '\\",\\"status7\\":\\"' +
                journeyStage +
                '\\",\\"text4\\":\\"' +
                comments +
                '\\"}';

            let query =
                'mutation { create_item (board_id: 5009300156, group_id: ' +
                fleetGroup +
                ', item_name: "' +
                fleet.companyName +
                '", column_values: "' +
                colValues +
                '") { id }}';

            console.log(query);

            axios({
                method: 'post',
                url: 'https://api.monday.com/v2',
                // responseType: asJson ? "json" : "arraybuffer",
                // data: data,
                data: JSON.stringify({
                    query: query
                }),
                // auth: { username: "api", password: "7787b560a3c3436fbd8500675ec28385" }, /// TODO: move into cloud key manager
                headers: {
                    'Content-Type': 'application/json',
                    Authorization:
                        'eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjI3NDczMjk3NiwiYWFpIjoxMSwidWlkIjoyOTgzOTEwMiwiaWFkIjoiMjAyMy0wOC0xMlQyMjo1OTozOC44NDBaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6MTE5MDcwODcsInJnbiI6InVzZTEifQ.aD_vr66yswbNrCmwe4wjAM3AkVrXrBZ24-P3aj4I8AI'
                }
            })
                .then((response) => {
                    console.log('/// ', response.data);
                    self.updateMondayStatus(newCompanyID);
                })
                .catch((err) => {
                    console.log('error occured', err);
                });
        },

        async saveNewFleet() {
            let self = this;
            this.addingFleet = true;

            let newCompanyID = uuidv4();

            let newData = {
                companyName: this.newcompany.companyName,
                companyState: this.newcompany.fleetLocation,
                companyLocation: this.newcompany.fleetLocation,
                fleetLocation: this.newcompany.fleetLocation,
                companyEmail: '',
                maxVessels: 0,
                primaryUserEmail: this.userData.useremail,
                primaryUserID: this.userData.userid,
                primaryAdminIDs: [this.userData.userid],
                primaryUserName: this.userData.username,
                primaryUserPhone: this.userData.userphone,
                fleetAvatarUrl: '',
                userAvatarUrl: this.userData.avatarUrl ?? '',
                newCompanyID: newCompanyID,
                showNoticeBoard: true
            };
            this.addRevision({
                userID: self.userData.userid,
                userName: self.userData.username,
                description: self.userData.username + ' has added a new Fleet called ' + self.newcompany.companyName
            });
            this.createNewFleet(newData);
            setTimeout(() => {
                this.close();
            }, 4000);
            // .then(() => {
            //     // this.addToMonday(this.newcompany, newCompanyID); // TODO: add back later..
            //     self.close();
            //     self.addRevision({
            //         userID: self.userData.userid,
            //         userName: self.userData.username,
            //         description: self.userData.username + ' has added a new Fleet called ' + self.newcompany.companyName
            //     });
            // })
            // .catch(function (error) {
            //     console.log('Error', error);
            //     self.close();
            // });
        },

        async getVesselResult(result) {
            if (!result.hasResult) {
                this.close();
            } else {
                this.isLoading = true;
                this.close();
                console.log(result);

                let fleetID = result.returnData.fleetID;

                // const trialEndDate = new Date();
                // trialEndDate.setDate(trialEndDate.getDate() + 730);
                // let newVesselData = {
                //     vesselOwnerID: this.currentFleetID,
                //     vesselName: result.returnData.vesselName.toUpperCase(),
                //     vesselUVI: result.returnData.vesselUVI,
                //     homePort: result.returnData.homePort,
                //     vesselOwnerName: this.currentFleet.companyName,
                //     vesselState: '',
                //     isDemo: false,
                //     isPaidFor: false,
                //     nextChargeDate: new Date(trialEndDate),
                //     lastVesselCharge: 0,
                //     nextVesselCharge: 0,
                //     isCompanyVessel: true,
                //     takesPassengers: true,
                //     aaaPlans: ['logs'],
                //     isActive: false,
                //     dateAdded: new Date(),
                //     isFreebie: true
                // };
                // const docRefA = await addDoc(collection(this.db, 'vessels'), newVesselData);

                // let newCompanyVessel = {
                //     vesselName: result.returnData.vesselName,
                //     vesselUVI: result.returnData.vesselUVI,
                //     vesselOwnerName: this.currentFleet.companyName,
                //     vesselOwnerID: this.currentFleetID
                // };
                // newCompanyVessel.vesselID = docRefA.id;

                // await setDoc(doc(this.db, 'companies/' + this.currentFleetID + '/companyVessels', docRefA.id), newCompanyVessel);

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.tab-wrapper {
    display: flex;
    justify-content: flex-end;
    .round-tab {
        display: flex;
        min-width: 270px;
        min-height: 70px;
        border-radius: 6px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        background: #f6f6f6;
        padding: 1.5rem;
        margin-right: 1rem;
        cursor: pointer;
        span {
            &.icon {
                margin-right: 1rem;
                font-size: 1.5rem;
                display: block;
                height: auto;
                width: auto;
            }
            &.text {
                font-size: 1.25rem;
            }
        }
        &.selected {
            background: #00d1b2;
            color: #fff;
        }
    }
    .text {
        font-family: 'Montserrat', sans-serif;
    }
}

h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.logs-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
}
.info-boxes {
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // grid-column-gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.tab-content {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    .tc-left {
        width: 50%;
        padding-right: 3rem;
        display: flex;
        flex-direction: column;
        align-items: start;
        img {
            max-width: 400px;
            width: 90%;
        }
    }
    .tc-right {
        width: 50%;
        &.pad-left {
            padding-left: 2rem;
        }
    }

    .download {
        margin-bottom: 2rem;
        text-align: center;
        a {
            display: inline-block;
            max-width: 270px;
            margin: 0 5px;
        }
        &.mini {
            a {
                max-width: 180px;
            }
        }
    }
}
.home-steps-list {
    margin-bottom: 2.5rem;
    .step-item {
        display: flex;
        margin-bottom: 20px;
        .counter {
            background-color: #7d2ae8;
            border-radius: 50%;
            width: 27px;
            height: 27px;
            display: flex;
            justify-content: center;
            color: #fff;
            align-items: center;
            /* font-weight: bold; */
            font-size: 0.85rem;
            margin-right: 8px;
            margin-top: 3px;
            span {
                width: 30px;
                text-align: center;
            }
        }
        .details {
            line-height: 1.7rem;
            font-size: 1.05rem;
        }
    }
}

.tab-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 30px;
}

@media (max-width: 767px) {
    .tab-grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 550px) {
    .tab-grid {
        grid-template-columns: 1fr;
    }
    .tab-wrapper {
        flex-direction: column;
        .round-tab {
            margin-bottom: 8px;
        }
    }
}

@media (max-width: 959px) {
    .tab-content {
        flex-direction: column;
        .tc-left {
            width: 100%;
            margin-bottom: 4rem;
            padding-right: 0;
            flex-direction: column;
            align-items: start;
        }
        .tc-right {
            width: 100%;
            &.pad-left {
                padding-left: 0;
            }
        }
    }
    .tab-wrapper {
        .round-tab {
            min-width: 0px;
            padding: 4px 12px;
            min-height: 0px;
            span {
                &.icon {
                    font-size: 16px;
                    margin-right: 8px;
                }
                &.text {
                    font-size: 16px;
                }
            }
        }
    }
}
</style>
