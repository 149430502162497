<template>
    <div>
        <div class="modal modal-fx-fadeInScale" v-bind:class="{ 'is-active': showModal }">
            <div class="modal-background" @click="close"></div>
            <div class="modal-card" :class="{ 'is-fullwidth': isFullWidth }">
                <header class="modal-card-head" :class="theme">
                    <p class="modal-card-title">{{ title }}</p>
                    <button class="delete" aria-label="hasSeenNotice" @click="close"></button>
                </header>

                <section class="modal-card-body">
                    <vue-element-loading :active="showLoading" spinner="bar-fade-scale" />
                    <slot>Loading content...</slot>
                </section>
                <footer class="modal-card-foot">
                    <button v-if="showCancel" class="button" @click="close">
                        {{ cancelText ? cancelText : 'Close' }}
                    </button>
                    <button v-if="showComplete" class="button" :class="theme" @click="submit" :disabled="disabled || showLoading">
                        {{ completeText ? completeText : 'Finish' }}
                    </button>
                    <slot name="extra-button"></slot>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import { currentDataStore } from '@/stores/currentdata';
import { loggedInUserStore } from '@/stores/userdata';
// import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, addDoc, getDocs, onSnapshot, orderBy, where, query, collection, updateDoc } from 'firebase/firestore';

export default {
    setup() {
        const current_data = currentDataStore();
        const user_store = loggedInUserStore();
        const db = getFirestore();
        return {
            current_data: current_data,
            user_store: user_store,
            db: db
        };
    },

    props: {
        title: String,
        theme: String,
        fullWidth: Boolean,
        cancelText: String,
        completeText: String,
        showCancel: Boolean,
        showComplete: Boolean,
        disabled: Boolean,
        showLoading: Boolean
    },

    components: {},

    mounted() {},

    data() {
        return {
            showModal: true,
            isFullWidth: this.fullWidth ?? false
        };
    },

    computed: {},

    methods: {
        close() {
            this.showModal = false;
            let result = {
                answer: 'Modal closed',
                modal: false
            };
            this.$emit('closed', result);
        },
        submit() {
            // this.showModal = false;
            let result = {
                answer: 'Action submitted',
                modal: false
            };
            this.$emit('submit', result);
        }
    }
};
</script>

<style lang="scss" scoped>
.modal {
    .modal-card {
        &.is-fullwidth {
            max-width: none;
        }
    }
}
.modal-card .modal-card-head p.modal-card-title {
    color: white;
    text-transform: none;
    font-weight: normal;
}
</style>
